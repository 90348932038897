<template>
  <layout>
    <template slot="body">
      <HeroSection
        image="referral_banner.jpg"
        :title="$t('sections.referral.heroSection.title')"
        hide-input
        :is-mobile="getBreakpoints.xs"
      />

      <div class="main-section-header">
        <div class="margin-container">
          <SectionHeader
            :title="$t('sections.referral.infoSection.title')"
            :subtitle="$t('sections.referral.infoSection.subtitle')"
            :description="$t('sections.referral.infoSection.description')"
          />
        </div>
      </div>

      <ReferralStepsSection />

      <div
        class="margin-container referral-form-section"
        ref="form-section"
      >
        <div class="form">
          <TitleStep
            number="1"
            :text="$t('sections.referral.form.step1')"
          />
          <VerticalTitleInput
            input-type="tertiary"
            :title="$t('sections.referral.form.address.value')"
            :placeholder="$t('sections.referral.form.address.placeholder')"
            :value="address"
            :valid="validAddress"
            :error-label="$t('components.input.missingField')"
            @input="(e) => handleInput('address', e)"
          />
          <div class="row">
            <div class="col-sm-6 col-12 mb-sm-0 mb-6">
              <VerticalTitleInput
                input-type="tertiary"
                :title="$t('sections.referral.form.city.value')"
                :placeholder="$t('sections.referral.form.city.placeholder')"
                :value="city"
                :valid="validCity"
                :error-label="$t('components.input.missingField')"
                @input="(e) => handleInput('city', e)"
              />
            </div>
            <div class="col-sm-3 col-12 mb-sm-0 mb-6">
              <VerticalTitleInput
                input-type="tertiary"
                :title="$t('sections.referral.form.postalCode.value')"
                :placeholder="
                  $t('sections.referral.form.postalCode.placeholder')
                "
                :value="postalCode"
                :valid="validPostalCode"
                :error-label="$t('components.input.invalidPostalCode')"
                @input="(e) => handleInput('postalCode', e)"
              />
            </div>
            <div class="col-sm-3 col-12">
              <VerticalTitleInput
                input-type="tertiary"
                :title="$t('sections.referral.form.district.value')"
                :placeholder="$t('sections.referral.form.district.placeholder')"
                :value="district"
                type="dropdown"
                :items="$t('arrays.districts')"
                :valid="validDistrict"
                :error-label="$t('components.input.missingField')"
                @input="(e) => handleInput('district', e)"
              />
            </div>
          </div>
          <TitleStep
            number="2"
            :text="$t('sections.referral.form.step2')"
          />
          <VerticalTitleInput
            input-type="tertiary"
            :title="$t('sections.referral.form.name.value')"
            :placeholder="$t('sections.referral.form.name.placeholder')"
            :value="name"
            :valid="validName"
            :error-label="$t('components.input.missingField')"
            @input="(e) => handleInput('name', e)"
          />
          <div class="row">
            <div class="col-sm-7 col-12 mb-sm-0 mb-6">
              <VerticalTitleInput
                input-type="tertiary"
                :title="$t('sections.referral.form.email.value')"
                :placeholder="$t('sections.referral.form.email.placeholder')"
                :value="email"
                :valid="validEmail"
                :error-label="$t('components.input.invalidEmail')"
                @input="(e) => handleInput('email', e)"
              />
            </div>
            <div class="col-sm-5 col-12 mb-sm-0 mb-6">
              <PhoneInput
                name="phone-number"
                input-type="tertiary"
                :title="$t('sections.referral.form.phone.value')"
                :placeholder="$t('sections.referral.form.phone.placeholder')"
                :code-placeholder="
                  $t('sections.referral.form.countryCode.placeholder')
                "
                :valid="validPhone"
                :valid-code="validPhoneCountryCode"
                :error-label="$t('components.input.invalidPhone')"
                :value="phone"
                :code-value="countryCode"
                :items="$t('arrays.phoneCountryCodes')"
                @input="(e) => handleInput('phone', e)"
                @codeInput="(e) => handleInput('countryCode', e)"
              />
            </div>
          </div>
          <VerticalTitleInput
            type="textarea"
            input-type="tertiary"
            :title="$t('sections.referral.form.additionalInfo.value')"
            :placeholder="
              $t('sections.referral.form.additionalInfo.placeholder')
            "
            :value="additionalInfo"
            :min-height="150"
            :max-height="200"
            :max-length="1200"
            @input="(e) => handleInput('additionalInfo', e)"
          />
          <BaseButton
            large
            :label="$t('sections.referral.form.submitButton')"
            class="align-self-center"
            :disabled="!validFields"
            @click="sendReferral"
          />
        </div>
      </div>
    </template>
  </layout>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  HeroSection,
  SectionHeader,
  TitleStep,
  VerticalTitleInput,
  BaseButton,
  PhoneInput,
} from 'ff-components-lib';
import { validateZipCode, validateEmail, validatePhone } from '@/utils';

import Layout from '@/components/layout/Layout';
import ReferralStepsSection from '@/components/sections/external/referral/ReferralStepsSection';

export default {
  name: 'ReferralScreen',
  components: {
    Layout,
    HeroSection,
    SectionHeader,
    ReferralStepsSection,
    TitleStep,
    VerticalTitleInput,
    BaseButton,
    PhoneInput,
  },
  data() {
    return {
      address: undefined,
      city: undefined,
      postalCode: undefined,
      district: undefined,
      name: undefined,
      email: undefined,
      countryCode: undefined,
      phone: undefined,
      additionalInfo: undefined,
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
    validAddress() {
      return (
        this.address === undefined
        || (typeof this.address === 'string' && this.address.length > 0)
      );
    },
    validCity() {
      return (
        this.city === undefined
        || (typeof this.city === 'string' && this.city.length > 0)
      );
    },
    validPostalCode() {
      if (this.postalCode === undefined) {
        return true;
      }
      if (this.postalCode && this.postalCode.length > 0) {
        return validateZipCode(this.postalCode);
      }
      return false;
    },
    validDistrict() {
      return (
        this.district === undefined
        || (typeof this.district === 'string' && this.district.length > 0)
      );
    },
    validName() {
      return (
        this.name === undefined
        || (typeof this.name === 'string' && this.name.length > 0)
      );
    },
    validEmail() {
      if (this.email === undefined) {
        return true;
      }
      if (this.email && this.email.length > 0) {
        return validateEmail(this.email);
      }
      return false;
    },
    validPhoneCountryCode() {
      return (
        this.countryCode === undefined
        || (this.countryCode && this.countryCode.length > 0)
      );
    },
    validPhone() {
      if (this.phone === undefined) {
        return true;
      }
      if (this.phone && this.phone.length > 0) {
        return validatePhone(this.phone);
      }
      return false;
    },
    validFields() {
      return (
        this.address
        && this.validAddress
        && this.city
        && this.validCity
        && this.postalCode
        && this.validPostalCode
        && this.district
        && this.validDistrict
        && this.name
        && this.validName
        && this.email
        && this.validEmail
        && this.countryCode
        && this.validPhoneCountryCode
        && this.phone
        && this.validPhone
      );
    },
  },
  mounted() {
    if (this.$route.params.scroll) {
      const element = this.$refs['form-section'];
      const top = element.offsetTop - 120;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
    },
    async sendReferral() {
      try {
        await this.$store.dispatch('sendReferralEmail', {
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          district: this.district,
          name: this.name,
          email: this.email,
          countryCode: this.countryCode,
          phone: this.phone,
          additionalInfo: this.additionalInfo,
        });

        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.referralSuccess'),
          type: 'success',
        });

        this.resetFields();
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    resetFields() {
      this.address = undefined;
      this.city = undefined;
      this.postalCode = undefined;
      this.district = undefined;
      this.name = undefined;
      this.email = undefined;
      this.countryCode = undefined;
      this.phone = undefined;
      this.additionalInfo = undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-section-header {
  height: 500px;
  background: linear-gradient(
    180deg,
    rgba($primary, 0.3) 0%,
    rgba($primary, 0) 100%
  );
  margin-bottom: 64px;

  .margin-container {
    display: flex;
    justify-content: center;
    margin-top: 134px;
  }
}

.steps-section-wrapper {
  margin-bottom: 108px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  background: white;
  border-radius: 8px;
  width: 60%;
  padding: 24px;
}

.referral-form-section {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

@media only screen and (max-width: 770px) {
  .hero-section {
    margin-bottom: 84px;
  }

  .main-section-header {
    height: 424px;
    margin-bottom: 0;

    .margin-container {
      margin-top: 78px;
    }
  }

  .steps-section-wrapper {
    margin-bottom: 0;
  }

  .referral-form-section {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1500px) {
  .form {
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) {
  .form {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .form {
    width: 100%;
  }
}
</style>
